<template>
  <BasicModal
    :action="deletePatch"
    action-title="Delete Patch"
    :action-button-classes="['danger']"
  >
    <div class="header">
      <ErrorIcon />
      <h4>Delete Patch?</h4>
    </div>
    <p>
      Are you sure you want to delete this Patch? The Patch's History (activity
      logs) will <strong>not</strong> be deleted.
      <br>
      <br>
      <strong class="text-danger-700">This action is permanent and cannot be undone.</strong>
    </p>
  </BasicModal>
</template>

<script>

  import BasicModal from '@/components/modals/_BasicModal.vue'

  import ErrorIcon from '@/assets/icons/error.svg'

  export default {
    components: {
      ErrorIcon,
      BasicModal,
    },
    props: {
      patch: {
        type: Object,
        required: true,
      },
      onSuccess: {
        type: Function,
        required: true,
      },
    },
    methods: {
      deletePatch() {
        return this.$store.dispatch('api/DELETE_USER_PATCH', this.patch.id)
          .then(() => {
            this.onSuccess()
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Patch deleted!',
              type: 'success',
            })
          })
          .catch(() => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Could not delete patch!',
              type: 'error',
            })
          })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .header
    @apply mb-6
    @apply space-x-2

    @apply flex
    @apply items-center

    h4
      @apply mb-0

    svg
      @apply w-8
      @apply h-8
      @apply text-danger-700

</style>
